import React from "react"
import RealizationInfo from "../../components/Organisms/RealizationInfo/RealizationInfo"
import RealizationPhotos from "../../components/Molecules/realizationPhotos/RealizationPhotos"
import { graphql } from "gatsby"
import photo1 from "../../assets/images/realizations/czyzew/1.jpg"
import photo2 from "../../assets/images/realizations/czyzew/2.jpg"
import photo3 from "../../assets/images/realizations/czyzew/3.jpg"
import ustka from "../../assets/images/realizations/ustka/ustka.jpg"
import zambrow from "../../assets/images/realizations/zambrow/zambrow.jpg"
import gizycko from "../../assets/images/realizations/gizycko/gizycko.jpg"
import OtherRealizations from "../../components/Organisms/OtherRealizations/OtherRealizations"
import CTABanner from "../../components/Organisms/CTABanner/CTABanner"
import {WithIntl} from "../../components/Atoms/withTranslate"
import { localize } from "../../components/Atoms/Language"

const CzyzewPage = ({ data, language }) => {
  const images = [photo1, photo2, photo3]
  const otherRealizations = [
    {
      image: ustka,
      name: localize("289"),
      type: localize("187"),
      url: "ustka"
    },
    {
      image: zambrow,
      name: localize("290"),
      type: localize("186"),
      url: "zambrow",
    },
    {
      image: gizycko,
      name: localize("291"),
      type: localize("295"),
      url: "gizycko",
    },
  ]
  return (
    <>
      <RealizationInfo open={1} closed={2} name={localize("288")} image={data.file.childImageSharp.fixed}>
        <li className="gray600">{localize("331")}</li>
        <li className="gray600">{localize("332")}</li>
        <li className="gray600">{localize("329")}</li>
        <li className="gray600">{localize("333")}</li>
      </RealizationInfo>
      <RealizationPhotos images={images}/>
      <OtherRealizations language={language} realizations={otherRealizations}/>
      <CTABanner language={language}/>
    </>
  )
}

export const query = graphql`
  {
    file(name: { eq: "czyzew" }) {
      childImageSharp {
        fixed(width: 540, height: 400){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default WithIntl()(CzyzewPage)
